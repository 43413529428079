import styled, { css } from "styled-components";

const StyledToolbar = styled.div`
  display: flex;
  gap: 0.5em;
  padding: 0.25em;
  background-color: ${({ $type }) => `var(--${$type}-bg-clr)`};
  flex-wrap: wrap;

  & > .toolbar__item + .toolbar__item::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -0.25em;
    background-color: ${({ $type }) => `var(--${$type}-border-clr)`};
    border-radius: 50%;
  }

  ${({ $type }) => {
    return css`
      & .toolbar__item {
        background-color: var(--${$type}-bg-clr);
        color: var(--toolbar-txt-clr);
      }

      & .toolbar__item--button:hover,
      & .toolbar__item--button:focus-visible {
        background-color: var(--${$type}-bg-clr-hover);
        color: var(--toolbar-txt-clr-hover);
      }

      & .toolbar__item--button:focus-visible {
        outline: 4px solid var(--toolbar-outline-clr);
      }

      & .toolbar__item--button:disabled {
        color: var(--toolbar-txt-clr-disabled);
        cursor: not-allowed;
      }
    `;
  }}
`;

const StyledToolbarItem = styled.span`
  font-size: inherit;
  position: relative;
  border-radius: 0.25em;
  min-width: max-content;
  border: none;
  display: flex;
  align-items: stretch;
`;

const StyledToolbarButton = styled(StyledToolbarItem).attrs({ as: "button" })`
  transition:
    background-color var(--transition-duration-normal, 250ms) ease-in-out,
    color var(--transition-duration-normal, 250ms) ease-in-out,
    outline var(--transition-duration-normal, 250ms) ease-in-out;
  outline-offset: -2px;
  padding: 0.25em 0.5em;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
`;

/**
 * GWOCU's toolbar component that sits at the top of a panel section.
 *
 * Can be wrapped around a sticky header for a sticky behavior in a panel with overflowing content.
 *
 * @component
 * @param {Object} props - The props for the toolbar component.
 * @param {React.ReactNode} props.children - The content of the toolbar, typically `Toolbar.Item` or `Toolbar.Button` components.
 * @param {string} [props.className=undefined] - Additional className for custom styles. Default value: `undefined`.
 * @param {"panel" | "main-content"} [props.type="panel"] - Additional className for custom styles. Default value: `panel`.
 * @returns {JSX.Element} The rendered toolbar component.
 *
 * @example
 * <Toolbar>
 *   <Toolbar.Button>I'm a button<Toolbar.Button>
 *   <Toolbar.Item><a href="https://studio.gwocu.com">I'm a link</a><Toolbar.Item>
 * </Toolbar>
 */
export const Toolbar = ({ children, className, type = "panel" }) => {
  const classes = `toolbar ${className || ""}`;

  return (
    <StyledToolbar className={classes} $type={type}>
      {children}
    </StyledToolbar>
  );
};

/**
 * GWOCU's toolbar button component.
 *
 * It is and behaves like a button, with hover highlighting and a pointer cursor.
 *
 * @memberof Toolbar
 * @param {Object} props - The props for the toolbar button component.
 * @param {React.ReactNode} props.children - The content of the toolbar button.
 * @param {string} [props.className=undefined] - Additional className for custom styles. Default value: `undefined`.
 * @param {boolean} [props.disabled=false] - If `true`, the button is disabled. Default value: `false`.
 * @param {function} [props.onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @returns {JSX.Element} The rendered toolbar button component.
 */
Toolbar.Button = ({ children, className, disabled = false, onClick }) => {
  const classes = `toolbar__item toolbar__item--button ${className || ""}`;

  return (
    <StyledToolbarButton
      className={classes}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </StyledToolbarButton>
  );
};

/**
 * GWOCU's toolbar item component.
 *
 * A generic slot for placing custom content like text, buttons, icons, links, or images.
 * This component does not highlight or change the cursor on hover by default.
 *
 * @memberof Toolbar
 * @param {Object} props - The props for the toolbar item component.
 * @param {React.ReactNode} props.children - The content of the toolbar item.
 * @param {string} [props.className] - Additional className for custom styles. Default value: `undefined`.
 * @returns {JSX.Element} The rendered toolbar item component.
 */
Toolbar.Item = ({ children, className }) => {
  const classes = `toolbar__item ${className || ""}`;

  return <StyledToolbarItem className={classes}>{children}</StyledToolbarItem>;
};
