import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .layout-text-content__centered-container {
    flex: 1;
  }
`;

const StyledNavbarSlot = styled.header`
  position: sticky;
  top: 0;
  z-index: var(--z-index-topbar);
  border-bottom: 1px solid var(--navbar-border-clr);;
`;

const StyledCols = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;

  & .layout-text-content__cols {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
    gap: 7.2rem;
    width: 100%;
    max-width: 104rem;
  }

  @media (min-width: 47em) {
    .layout-text-content__col:first-of-type {
      align-items: flex-start;
    }

    .layout-text-content__col:last-of-type{
      align-items: flex-end;
    }
  }
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

const StyledHeading = styled.h1`
  font-size: 4.8rem;
  font-weight: 700;
  color: var(--clr-txt-heading-primary);
`;

const StyledDescription = styled.p`
  font-size: 2.4rem;
  color: var(--clr-txt-heading-secondary);
`;

/**
 * GWOCU's LayoutTextAndContent component
 *
 * This component provides the structured layout used in the /login and /updateuser pages.
 *
 * It creates two columns that adapt into rows on smaller screens. It can optionally include a navbar.
 *
 * @component
 * @param {Object} props - The props for the layout container.
 * @param {React.ReactNode} props.children - The content to be rendered inside the layout.
 * @returns {JSX.Element} The rendered layout component.
 *
 * @example
 * <LayoutTextAndContent>
 *   <LayoutTextAndContent.NavbarSlot>
 *     <Topbar />
 *   </LayoutTextAndContent.NavbarSlot>
 *   <LayoutTextAndContent.Cols>
 *     <LayoutTextAndContent.Col>Column 1 content</LayoutTextAndContent.Col>
 *     <LayoutTextAndContent.Col>Column 2 content</LayoutTextAndContent.Col>
 *   </LayoutTextAndContent.Cols>
 * </LayoutTextAndContent>
 */
export const LayoutTextAndContent = ({ children }) => (
  <StyledContainer className="layout-text-content">
    {children}
  </StyledContainer>
);

/**
 * GWOCU's navbar slot component
 *
 * An optional slot for placing a navbar within the layout.
 * It'll make the navbar sticky in case of overflowing content.
 *
 * @memberof LayoutTextAndContent
 * @param {Object} props - The props for the navbar slot.
 * @param {React.ReactNode} props.children - The navbar content.
 * @returns {JSX.Element} The rendered navbar slot.
 */
LayoutTextAndContent.NavbarSlot = ({ children }) => (
  <StyledNavbarSlot className="layout-text-content__navbar-container">
    {children}
  </StyledNavbarSlot>
);

/**
 * GWOCU's columns component
 *
 * This component defines the 2 columns layout, creating two columns (or rows on smaller screens).
 *
 * @memberof LayoutTextAndContent
 * @param {Object} props - The props for the columns layout.
 * @param {React.ReactNode} props.children - The 2 columns to be rendered.
 * @returns {JSX.Element} The rendered columns.
 */
LayoutTextAndContent.Cols = ({ children }) => (
  <StyledCols className="layout-text-content__centered-container">
    <div className="layout-text-content__cols">{children}</div>
  </StyledCols>
);

/**
 * GWOCU's column component
 *
 * Represents a single column in the layout.
 * Can contain any content, but typically has a form or text.
 *
 * @memberof LayoutTextAndContent
 * @param {Object} props - The props for the column.
 * @param {React.ReactNode} props.children - The content of the column.
 * @returns {JSX.Element} The rendered column.
 */
LayoutTextAndContent.Col = ({ children }) => (
  <StyledCol className="layout-text-content__col">{children}</StyledCol>
);


/**
 * GWOCU's heading component
 *
 * A heading for the layout, typically used to display main titles in a column.
 *
 * @memberof LayoutTextAndContent
 * @param {Object} props - The props for the heading.
 * @param {React.ReactNode} props.children - The heading content.
 * @returns {JSX.Element} The rendered heading.
 *
 * @example
 * <LayoutTextAndContent.Col>
 *   <LayoutTextAndContent.Heading>GWOCU Studio</LayoutTextAndContent.Heading>
 *   <LayoutTextAndContent.Description>AI at the service of API integration and technical writing/LayoutTextAndContent.Description>
 * </LayoutTextAndContent.Col>
 */
LayoutTextAndContent.Heading = ({ children }) => (
  <StyledHeading className="layout-text-content__heading">
    {children}
  </StyledHeading>
);

/**
 * GWOCU's description component
 *
 * A description for the layout, often used alongside the heading in a column.
 *
 * @memberof LayoutTextAndContent
 * @param {Object} props - The props for the description.
 * @param {React.ReactNode} props.children - The description content.
 * @returns {JSX.Element} The rendered description.
 *
 * @example
 * <LayoutTextAndContent.Col>
 *   <LayoutTextAndContent.Heading>GWOCU Studio</LayoutTextAndContent.Heading>
 *   <LayoutTextAndContent.Description>Build. Test. Sell. Faster.<LayoutTextAndContent.Description>
 * </LayoutTextAndContent.Col>
 */
LayoutTextAndContent.Description = ({ children }) => (
  <StyledDescription className="layout-text-content__description">
    {children}
  </StyledDescription>
);
