import React from 'react'
import styled from 'styled-components'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  width: 100%;

  & > *:first-child {
    margin-top: 0;
  }
`;

const StyledFormControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75em;
  margin-top: 0.5em;

  & .form__group, & .form__row {
    margin-top: 0;
  }
`;

const StyledFormCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75em;
  margin-top: 0.5em;

  & .form__row {
    margin-top: 0;
  }
`;

const StyledFormRow = styled.div`
  display: flex;
  gap: 0.75em;
  margin-top: 0.5em;
  align-items: flex-start;

  & > .form__control {
    margin-top: 0;
  }
`;

const StyledFormLabel = styled.label`
  display: block;
`;

/**
 * GWOCU's form component
 *
 * A container for stacking form content vertically.
 * Use the form layout components: `Form.Control`, `Form.Col`, and `Form.Row` as direct children for layout and grouping.
 * You can nest `Form.Control`, `Form.Col`, and `Form.Row` components within each other as needed.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content inside the form.
 * @param {string} [props.className=undefined] - Adds a custom `className` for the form. Default value: `undefined`.
 * @param {function} [props.onSubmit=undefined] - The `submit` event handler. Default value: `undefined`.
 * @returns {JSX.Element} The rendered form component.
 *
 * @example
 * <Form>
 *   <Form.Control>
 *     <Form.Label htmlFor="prompt">Type your prompt</Form.Label>
 *     <InputText id="prompt" value={value} onChange={handleChange} />
 *   </Form.Control>
 *   <Form.Control>
 *     <Form.Label>Choose an LLM model</Form.Label>
 *     <Form.Col>
 *       <RadioButton name="llm" id="radio-llama" value="llama">LLaMA</RadioButton>
 *       <RadioButton name="llm" id="radio-gpt" value="gpt">GPT</RadioButton>
 *       <RadioButton name="llm" id="radio-lamda" value="lamda">LaMDA</RadioButton>
 *     </Form.Col>
 *   </Form.Control>
 *   <Form.Row>
 *     <Button color="secondary" onClick={handleCancel}>Cancel</Button>
 *     <Button type="submit" onClick={handleSubmit}>Send</Button>
 *   </Form.Row>
 * </Form>
 */
export const Form = ({children, className, onSubmit}) => {
  const classes = `form ${className || ""}`;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  }

  return (
    <StyledForm className={classes} onSubmit={handleSubmit}>{children}</StyledForm>
  )
}

/**
 * GWOCU's form control component
 *
 * Use this component for form fields that include a `Form.Label`.
 *
 * A form control is composed of a `Form.Label` and an input component (e.g., `InputPassword`, `Select`, `Textarea`),
 * or a `Form.Label` combined with a group of inputs (e.g., `Checkbox`, `RadioButton`, `InputText`)
 * arranged using `Form.Row` or `Form.Col`."
 *
 * You can also nest `Form.Control` within `Form.Row`s and `Form.Col`s.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content inside the form control. Should include a `Form.Label`.
 * @param {string} [props.className=undefined] - Adds a custom `className` for the form control. Default value: `undefined`.
 * @returns {JSX.Element} The rendered form control component.
 *
 * @example
 * <Form.Control>
 *   <Form.Label htmlFor="link-type">Choose a link type<Form.Label>
 *   <Select id={link-type} options={options} onChange={handleChange} />
 * </Form.Control>
 */
Form.Control = ({children, className}) => {
  const classes = `form__control ${className || ""}`;
  return <StyledFormControl className={classes}>{children}</StyledFormControl>
}

/**
 * GWOCU's form column component
 *
 * Used for stacking multiple form elements or form layout components (`Form.Control` and `Form.Row`) vertically.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content inside the column.
 * @param {string} [props.className=undefined] - Adds a custom className for the column. Default value: `undefined`.
 * @returns {JSX.Element} The rendered form column component.
 *
 * @example
 * const [roles, setRoles] = useState({
 *  designer: false,
 *  owner: false,
 *  reader: true
 * });
 *
 * const chooseRole = (e) => {
 *  const { name, checked } = e.target;
 *  setRoles(prevRoles => ({...prevRoles, [name]: checked}))
 * };
 *
 * return (
 *  <Form>
 *    <Form.Control>
 *      <Form.Label>Update user roles</Form.Label>
 *      <Form.Col>
 *        <Checkbox name="designer" checked={roles.designer} onChange={chooseRole}>Designer</Checkbox>
 *        <Checkbox name="owner" checked={roles.owner} onChange={chooseRole}>Owner</Checkbox>
 *        <Checkbox name="reader" checked={roles.reader} onChange={chooseRole}>Reader</Checkbox>
 *      </Form.Col>
 *    </Form.Control>
 * </Form>
* );
 */
Form.Col = ({children, className}) => {
  const classes = `form__group ${className || ""}`
  return <StyledFormCol className={classes}>{children}</StyledFormCol>
}

/**
 * GWOCU's form row component
 *
 * Used for arranging multiple form elements or layout components (e.g., Form.Control, Form.Col) in a horizontal row.
 * You can also nest `Form.Row` within `Form.Control`s and `Form.Col`s.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content inside the row.
 * @param {string} [props.className=undefined] - Adds a custom className for the row. Default value: `undefined`.
 * @returns {JSX.Element} The rendered form row component.
 *
 * @example
 * <Form.Row>
 *   <Form.Control>
 *     <Form.Label>Header</Form.Label>
 *     <InputText name="header" defaultValue="Content-Type" />
 *   <Form.Control>
 *   <Form.Control>
 *     <Form.Label>Value</Form.Label>
 *     <InputText name="value" defaultValue="application/json" />
 *   <Form.Control>
 * </Form.Row>
 */
Form.Row = ({children, className}) => {
  const classes = `form__row ${className || ""}`
  return <StyledFormRow className={classes}>{children}</StyledFormRow>
}

/**
 * GWOCU's form label component
 *
 * The label for form controls, required inside `Form.Control` components.
 *
 * Provides a label for other form elements and form layout components.
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content of the label.
 * @param {string} [props.className=undefined] - Adds a custom className for the label. Default value: `undefined`.
 * @param {string} [props.htmlFor=undefined] - Associates the label with a specific form control. Default value: `undefined`.
 * @returns {JSX.Element} The rendered form label component.
 *
 * @example
 * <Form.Control>
 *   <Form.Label>Task description<Form.Label>
 *   <TextEditor value={value} onChange={changeHandler} />
 * <Form.Control>
 */
Form.Label = ({children, className, htmlFor}) => {
  const classes = `form__label ${className || ""}`
  return <StyledFormLabel className={classes} htmlFor={htmlFor}>{children}</StyledFormLabel>
}
