import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Button, Icon, Form, InputText, Modal, Textarea, Tooltip } from '../../UI';
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import tooltips from '../../tooltips/tooltips';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import "./thirdparties.css";
import yaml from 'js-yaml';

const Thirdparties = ({clientNr, explorerId, open, onClose}) => {
  const INITIAL_FORM_DATA = {
    clientNr: clientNr,
    name: '',
    description: '',
    yaml: '',
  }

  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDefaultYaml, setSelectedDefaultYaml] = useState("");
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  useEffect(() => {
    if(!open) return;
    // Fetch all records when the component mounts
    fetchRecords();
    // fetch default yaml
    FetchYamlRecord();
  }, [open]);

  useEffect(() => {
    const MyFormdata = {
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    } 
    setFormData(selectedRecord || MyFormdata);
  }, [selectedRecord]);

  const FetchYamlRecord = async () => {
    const payloadYaml = {
      yamlId: "1"
    }

    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/yaml/query', encodebody(payloadYaml));
      setFormData((prevData) => ({
        ...prevData,
        yaml: getDecodedBody(response.data).yaml,
      }));
      setSelectedDefaultYaml(getDecodedBody(response.data).yaml);
    } catch (error) {
      console.error('Error fetching yaml:', error);
    }
  }

  const fetchRecords = async () => {
    const payload ={
      clientNr: clientNr
    }

    try {
      const response = await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/queryall', encodebody(payload));
      setRecords(getDecodedBody(response.data));
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  };

  const handleSelectRecord = (record) => {
    setSelectedRecord(prevRecord => record === prevRecord ? null : record);
  };

  const handleAddMode = () => {
    fetchRecords();
    setFormData({
      ...INITIAL_FORM_DATA,
      yaml: selectedDefaultYaml, // Use the default YAML
    });
    setSelectedRecord(null); 
    };

  const handleClose = () => {
    onClose?.()
    handleSelectRecord(null)
  }

  const handleDeleteRecord = async () => {
    const payload = {
      clientNr: clientNr,
      name: selectedRecord.name
    }

    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/delete', encodebody(payload));
      await fetchRecords();
      // Reset form to initial state
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
      // Clear selected record
      setSelectedRecord(null);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  function validateYaml(yamlString) {
    try {
      // Try to parse the YAML string
      yaml.load(yamlString);
      // If parsing succeeds, return true
      return true;
    } 
    catch (error) {
      // If an error occurs during parsing, return false
      alert('Invalid YAML. Please check your YAML syntax.');
      return false;
    }
  };

 

  const handleUpdateRecord = async () => {
    if (!formData.name || !formData.yaml) {
      alert('Name and YAML fields cannot be empty.');
      return;
    }
  
    // Validate YAML before updating
    if (!validateYaml(formData.yaml)) {
      return; // If invalid, exit early
    }
  
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/update', encodebody(formData));
      fetchRecords();
      
      setFormData({
        clientNr: clientNr,
        name: '',
        description: '',
        yaml: '',
      });
      setSelectedRecord(null);
    } catch (error) {
      alert('Error updating API Action. Please check your fields.');
    }
  };
  

  const handleAddRecord = async () => {
    if (!formData.name || !formData.yaml) {
      alert('Name and YAML fields cannot be empty.');
      return;
    }
  
    // Validate YAML before adding
    if (!validateYaml(formData.yaml)) {
      return; // If invalid, exit early
    }
  
    try {
      await axios.post(process.env.REACT_APP_CENTRAL_BACK + '/thirdparties/register', encodebody(formData));
      await fetchRecords();
      setFormData({
        ...INITIAL_FORM_DATA,
        yaml: selectedDefaultYaml, // Use the default YAML
      });
    } catch (error) {
      alert('Error adding API action. Please check your fields.');
    }
  };
  

  return (
    <Modal open={open} onClose={handleClose} maxWidth="80rem">
      <Modal.Title>API Actions</Modal.Title>
      <Modal.Body>
        <div className="third-parties__table-container">
          <table>
            <thead>
              <tr>
                <th>Main Client</th>
                <th>Api Action Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record._id} className={selectedRecord?._id === record._id ? "table__row--selected" : `${selectedRecord?.clientNr}--${record.clientNr}`} onClick={() => handleSelectRecord(record)}>
                  <td>{record.clientNr}</td>
                  <td>{record.name}</td>
                  <td>{record.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Tooltip tooltip={tooltips.thirdparties} placement="right" trigger="click" interactive={true}>
            <Icon interactive={true} className="table__icon--help">
              <HelpCenterIcon />
            </Icon>
          </Tooltip>
        </div>
        <Form>
          <InputText
            type="hidden"
            className="third-parties__form-input-client"
            value={formData.clientNr}
            onChange={(e) => setFormData({ ...formData, clientNr: e.target.value })}
          />
          <Form.Control>
            <Form.Label>
              Api Action Name:
            </Form.Label>
            <InputText
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>Description:</Form.Label>
            <InputText
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
          </Form.Control>
          <Form.Control>
            <Form.Label>YAML:</Form.Label>
            <Textarea
              value={formData.yaml}
              onChange={(e) => setFormData({ ...formData, yaml: e.target.value })}
              rows="5"
            />
          </Form.Control>
          <Form.Row>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {selectedRecord && (
            <Button onClick={handleAddMode}>New Action</Button>
          )}
          {selectedRecord ? (
            <>
              <Button color="danger" onClick={handleDeleteRecord}>
                Delete
              </Button>
              <Button onClick={handleUpdateRecord}>Update</Button>
            </>
          ) : (
            <Button onClick={handleAddRecord}>Add Action</Button>
          )}
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Thirdparties;
