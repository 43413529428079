import { forwardRef, useState } from "react";
import styled from "styled-components";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { blink } from "./animations";

const StyledInputContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover input {
    border-color: var(--input-border-clr-hover);
  }

  &.invalid input {
    border-color: var(--input-border-clr-invalid);
    color: var(--input-txt-clr-invalid);
    background-color: var(--input-bg-clr-invalid);
    caret-color: var(--input-txt-clr-invalid);
  }

  &.invalid:hover input,
  &.invalid input:focus-visible {
    color: var(--input-txt-clr-invalid);
    border-color: var(--input-border-clr-invalid-hover);
    background-color: var(--input-bg-clr-invalid);
  }

  &.invalid .input__button {
    color: var(--input-icon-clr-invalid);
  }

  &.invalid .input__button:hover,
  &.invalid .input__button:focus-visible {
    background-color: transparent;
    border-color: var(--input-border-clr-invalid-hover);
    color: var(--input-icon-clr-invalid);
  }

  &.invalid input::placeholder {
    color: var(--input-placeholder-clr-invalid);
  }

  &.invalid input:focus-visible,
  &.invalid .input__button:focus-visible {
    border-color: var(--input-border-clr-invalid-hover);
    outline-color: var(--input-border-clr-invalid-hover);
  }

  &:hover input:read-only {
    border-color: transparent;
  }

  &:hover input:disabled {
    border: 2px solid var(--input-border-clr-disabled);
  }
`;

export const BaseInput = styled.input`
  font-family: inherit;
  font-size: inherit;
  background-color: var(--input-bg-clr);
  color: var(--input-txt-clr);
  border: 2px solid var(--input-border-clr);
  caret-color: var(--input-caret-clr);
  padding: 0.5em 0.75em;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "initial")};
  border-radius: 0.5em;
  transition: border-color var(--transition-duration-normal, 250ms) ease,
              outline var(--transition-duration-normal, 250ms) ease;

  /* Prevents the placeholder from being lighter than specified in Firefox */
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #FFF;
  }

  &:-moz-placeholder,
  &::-moz-placeholder {
      opacity: 1;
  }

  &::placeholder {
    color: var(--input-placeholder-clr);
  }

  &:hover,
  &:active,
  &:focus-visible {
    border-color: var(--input-border-clr-hover);
    color: var(--input-txt-clr-hover);
    background-color: var(--input-bg-clr-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--input-border-clr-hover);
    animation: ${blink} 2s infinite;
  }

  &:read-only {
    border-color: transparent;
    animation: none;
    outline: none;
  }

  &:disabled {
    background-color: var(--input-bg-clr-disabled);
    color: var(--input-txt-clr-disabled);
    border-color: var(--input-border-clr-disabled);
    animation: none;
    outline: none;
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: var(--input-placeholder-clr-disabled);
  }
`;

const InputWithIcon = styled(StyledInputContainer)`
  & .input__button svg {
    display: block;
    height: 1.5em;
    width: 1.5em;
    color: inherit;
  }

  & .input__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--input-icon-button-bg-clr);
    border: 2px solid var(--input-icon-button-border-clr);
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0.5em;
    height: 100%;
    aspect-ratio: 1;
    transition: background-color var(--transition-duration-slow) ease,
                border-color var(--transition-duration-slow) ease;
    color: var(--input-icon-clr);
    cursor: pointer;
  }

  & .input__button:hover,
  & .input__button:focus-visible {
    background-color: var(--input-icon-button-bg-clr-hover);
    border-color: var(--input-icon-button-border-clr-hover);
    color: var(--input-icon-clr-hover);
  }

  & .input__button:focus-visible {
    outline: 2px solid var(--input-border-clr-hover);
  }

  &.disabled .input__button {
    color: var(--input-icon-clr-disabled);
    background-color: transparent;
    border-color: var(--input-border-clr-disabled);
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: not-allowed;
  }
`;

/**
 * @typedef {Object} InputProps
 * @property {boolean} [autoFocus=false] - If `true`, the input will be focused on the first mount. Default value: `false`.
 * @property {string} [autoComplete="off"] - Controls the browser's autocomplete behavior. Default value: `"off"`.
 * @property {string} [className=undefined] - Custom class names to apply to the input element. Default value: `undefined`.
 * @property {string} [defaultValue=undefined] - Initial value for the input. Default value: `undefined`.
 * @property {boolean} [disabled=false] - If `true`, the input will be disabled. Default value: `false`.
 * @property {boolean} [error=false] - If `true`, input will be styled to indicate an error. Default value: `false`.
 * @property {string} [id=undefined] - The `id` attribute for the input element. Default value: `undefined`.
 * @property {boolean} [fullWidth=true] - If `true`, the input will expand to fill the width of its container. Default value: `true`.
 * @property {number | string} [props.minLength=0] - The minimum required length (in characters) of `value`. Default value: `0`.
 * @property {number | string} [props.maxLength=undefined] - The maximum allowed length (in characters) of `value`. Default value: `undefined`.
 * @property {function} [onBlur=undefined] - Event handler for the `blur` event. Default value: `undefined`.
 * @property {function} [onChange=undefined] - Event handler for the `change` event. Default value: `undefined`.
 * @property {string} [placeholder=""] - Placeholder text for the input. Default value: `""`.
 * @property {boolean} [required=false] - If `true`, the input will be marked as required. Default value: `false`.
 * @property {boolean} [readOnly=false] - If `true`, the input will be marked as required. Default value: `false`.
 * @property {string} [value=""] - The current value of the input element. Default value: `""`.
 */

/**
 * Gwocu's custom text input component
 *
 * @component
 * @param {InputProps} props - The properties for the input component.
 * @param {"text" | "email" | "tel"} [props.type="text"] - The type attribute for the input element. Default value: `"text"`.
 * @param {React.Ref<HTMLInputElement>} ref - Reference to the input element.
 * @returns {JSX.Element} The rendered input component.
 */
export const InputText = forwardRef(
  (
    /** @type {InputProps} */ props,
    /** @type {React.RefAttributes<HTMLInputElement>} */ ref
  ) => {
    const { type, fullWidth = true, error, disabled, readOnly, ...rest } = props;
    const classes = `${error ? "invalid" : ""}${disabled ? " disabled" : ""}${readOnly ? "readOnly" : ""}`;
    return (
      <StyledInputContainer className={classes}>
        <BaseInput
          ref={ref}
          type={type}
          $fullWidth={fullWidth}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </StyledInputContainer>
    );
  }
);

/**
 * Gwocu's custom password input component
 *
 * @component
 * @param {InputProps} props - The props for the `input` component.
 * @param {React.Ref} ref - The ref passed to the `input` element.
 * @returns {JSX.Element} The rendered input component.
 */
export const InputPassword = forwardRef(
  (
    /** @type {InputProps & React.RefAttributes<HTMLInputElement>} */ props,
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const { fullWidth = true, error, className, disabled, readOnly, ...rest } = props;
    const classes = `${error ? " invalid" : ""}${disabled ? " disabled" : ""}`;

    const handletoggleVisibility = () => setVisible((prevState) => !prevState);

    return (
      <InputWithIcon className={classes}>
        <BaseInput
          $fullWidth={fullWidth}
          ref={ref}
          type={visible ? "text" : "password"}
          disabled={disabled}
          className={className}
          {...rest}
          />
        <button
          type="button"
          className="input__button"
          disabled={disabled}
          onClick={handletoggleVisibility}
        >
          {visible ? (
            <>
              <VisibilityOffOutlined className="input__button-icon" />
              <span className="visually-hidden">Hide password</span>
            </>
          ) : (
            <>
              <VisibilityOutlined className="input__button-icon" />
              <span className="visually-hidden">Show password</span>
            </>
          )}
        </button>
      </InputWithIcon>
    );
  }
);