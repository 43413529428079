import styled from "styled-components";
import { createPortal } from "react-dom";
import { Button } from "./Button";
import { CloseOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { fadeIn, showModal } from "./animations";

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--clr-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal, 4000);

  &.visible {
    animation: ${fadeIn} 0.3s forwards;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ $maxWidth }) => ($maxWidth ? "100%" : "fit-content")};
  max-width: ${({ $maxWidth }) => $maxWidth};
  display: flex;
  padding: 1rem;
  z-index: var(--z-index-modal, 4000);
  background-color: transparent;
`;

const StyledModal = styled.div`
  position: relative;
  background-color: var(--card-bg-clr);
  border-radius: 0.25em;
  /* box-shadow: 0 2.4rem 3.2rem rgba(0, 0, 0, 0.12); */
  padding: 1.6rem 2rem 2rem;
  transition: all var(--transition-duration-slow, 400ms) ease-in-out;
  max-height: 90vh;
  width: 100%;
  overflow: auto;
  opacity: 0;

  & .modal__close-button--top {
    transform: translate(-0.2em, -0.1em);
    transition: all var(--transition-duration-normal, 250ms) ease-in-out;
    position: absolute;
    top: 1em;
    right: 1em;
  }

  &.modal .modal__close-button--top svg {
    font-size: 1.25em;
  }

  /* Leaves room for the close button */
  &.modal--no-close-button .modal__title {
    margin-right: 0;
  }

  &.visible {
    /* animation: ${showModal} 0.3s forwards; */
    animation: 0.2s ease-in ${showModal} 0.1s forwards;
  }

  & .modal__close-button--top + .modal__body {
    margin-top: 3rem;
  }
`;

const StyledModalTitle = styled.header`
  border-bottom: 1px solid var(--card-border-clr);
  padding-bottom: 1.6rem;

  & .modal__title {
    font-size: 2rem;
    margin-right: 4.8rem;
    display: flex;
    align-items: center;
    gap: 0.25em;
  }
`;

const StyledModalBody = styled.section`
  &.modal__body {
    margin: 1.6rem 0 0;
  }
`;

const StyledModalFooter = styled.footer`
  &.modal__footer {
    display: flex;
    gap: 0.5em;
    margin-top: 1.6rem;
  }
`;

/**
 * GWOCU's modal component
 *
 * Contains all the parts of a modal.
 *
 * **Note:** This modal component **DOES NOT manage its open/close state internally**.
 *
 * It won't open unless a `true` value is passed for the `open` prop,
 * and it won't close dynamically unless you provide a handler for that.
 * If these props are not provided, the modal might appear unresponsive.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content inside the modal.
 * @param {string} [props.maxWidth="fit-content"] - Sets the maximum width of the modal. Default value: `"fit-content"`.
 * @param {boolean} [props.open=false] - Controls the visibility of the modal. Default value: `false`.
 * @param {function} props.onClose - Function responsible for closing the modal, including managing the modal's open state.
 * @param {boolean} [props.showOverlay=true] - If `true`, an overlay is shown behind the modal. Default value: `true`.
 * @param {boolean} [props.showCloseButton=true] - If `true`, a close button is displayed in the modal. Default value: `true`.
 * @returns {JSX.Element} The rendered modal component.
 *
 * @example
 * <Modal open={open} onClose={modalHandler}>
 *   <Modal.Title>Modal titles are optional</Modal.Title>
 *   <Modal.Body>I'm the content</Modal.Body>
 *   <Modal.Footer>
 *     <Button onClick={modalHandler}>Close</Button>
 *   </Modal.Footer>
 * </Modal>
 */
export const Modal = ({
  children,
  open = false,
  onClose,
  maxWidth = "fit-content",
  showOverlay = true,
  showCloseButton = true,
}) => {
  const classes = `modal-container ${showCloseButton ? "" : "modal--no-close-button"}`;
  const [modalClasses, setModalClasses] = useState("modal");
  const [overlayClasses, setOverlayClasses] = useState("overlay");

  useEffect(() => {
    setModalClasses((prevClasses) => prevClasses + " visible");
    setOverlayClasses((prevClasses) => prevClasses + " visible");
  }, []);

  return (
    open &&
    createPortal(
      <>
        {showOverlay && <StyledOverlay className={overlayClasses} />}
        <StyledContainer className={classes} $maxWidth={maxWidth}>
          <StyledModal className={modalClasses}>
            {showCloseButton && (
              <Button
                color="secondary"
                className="modal__close-button--top"
                rounded
                size="xs"
                onClick={onClose}
              >
                <CloseOutlined />
              </Button>
            )}
            {children}
          </StyledModal>
        </StyledContainer>
      </>,
      document.body
    )
  );
};

/**
 * GWOCU's modal title component
 *
 * This component is optional for the modal. If omitted, the modal will still display with a closing button.
 * @memberof Modal
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content of the modal title.
 * @param {string} [props.className=undefined] - Adds a custom className for the component. Default value: `undefined`.
 * @returns {JSX.Element} The rendered modal title component.
 */
Modal.Title = ({ children, className }) => {
  return (
    <StyledModalTitle className="modal__header">
      <h2 className={`modal__title ${className}`}>{children}</h2>
    </StyledModalTitle>
  );
};

/**
 * GWOCU's modal body component
 *
 * @memberof Modal
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content of the modal body.
 * @param {string} [props.className=undefined] - Adds a custom className for the component. Default value: `undefined`.
 * @returns {JSX.Element} The rendered modal body component.
 */
Modal.Body = ({ children, className }) => {
  return <StyledModalBody className={`modal__body ${className || ""}`}>{children}</StyledModalBody>;
};

/**
 * GWOCU's modal footer component
 *
 * This component is optional for the modal. It is most commonly used for buttons when no form is present in the modal body. In modals containing a form, it is generally recommended to handle the form submission and modal closing within the form itself rather than using this footer component.
 * @memberof Modal
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The content of the modal footer.
 * @param {string} [props.className=undefined] - Adds a custom className for the component. Default value: `undefined`.
 * @returns {JSX.Element} The rendered modal footer component.
 */
Modal.Footer = ({ children, className }) => {
  return (
    <StyledModalFooter className={`modal__footer ${className || ""}`}>{children}</StyledModalFooter>
  );
};
