import styled from "styled-components";
import { BaseInput } from "./Input";
import { forwardRef } from "react";
import { blink } from "./animations";

export const StyledTextarea = styled(BaseInput).attrs({ as: "textarea" })`
  padding: 0.75em;
  transition:
    border-color var(--transition-duration-normal, 250ms) ease,
    outline var(--transition-duration-normal, 250ms) ease;
  resize: ${({resize}) => resize};

  &:focus-visible {
    animation: ${blink} 2s infinite;
  }

  &.invalid {
    background-color: var(--input-bg-clr-invalid);
    color: var(--input-txt-clr-invalid);
    border-color: var(--input-border-clr-invalid);
  }

  &.invalid::placeholder {
    color: var(--input-placeholder-clr-invalid);
  }

  &.invalid:focus-visible {
    outline-color: var(--input-border-clr-invalid);
  }
`;

/**
 * @typedef {Object} TextareaProps
 * @property {string} [props.autoComplete="off"] - If different from `"off"`, allows autocompletion. Default value: `"off"`.
 * @property {boolean} [props.autoFocus=false] - If `true`, the `textarea` is focused during the first mount. Default value: `false`.
 * @property {string} [props.className=undefined] - Adds custom className for `textarea`. Default value: `undefined`.
 * @property {number | string} [props.cols=20] - The width of `textarea` in average character widths. Default value: `20`.
 * @property {boolean} [props.defaultValue=undefined] - Sets initial value for `textarea`. Default value: `undefined`.
 * @property {boolean} [props.disabled=false] - If `true`, the `textarea` is disabled. Default value: `false`.
 * @property {boolean} [props.error=false] - If `true`, the styling changes to signal the input is invalid. Default value: `false`.
 * @property {boolean} [props.fullWidth=true] - If true, the `textarea` will take up the full width of its container. Default value: `true`.
 * @property {string} [props.id=undefined] - The id of the `textarea` component. Default value: `undefined`.
 * @property {number | string} [props.minLength=0] - The minimum required length (in characters) of `value`. Default value: `0`.
 * @property {number | string} [props.maxLength=undefined] - The maximum allowed length (in characters) of `value`. Default value: `undefined`.
 * @property {string} [props.name=undefined] - The name of the `textarea` component. Default value: `undefined`.
 * @property {function} [props.onBlur=undefined] - The `blur` event handler. Default value: `undefined`.
 * @property {function} [props.onChange=undefined] - The `change` event handler. Default value: `undefined`.
 * @property {string} [props.placeholder=undefined] - The placeholder text. Default value: `undefined`.
 * @property {boolean} [readOnly=false] - If `true`, the textarea will be read-only. Default value: `false`.
 * @property {boolean} [props.required=false] - If `true`, checking the `textarea` is required. Default value: `false`.
 * @property {"none" | "both" | "horizontal" | "vertical"} [props.resize="none"] - Defines the `textarea` resize behavior. Default value: `"none"`.
 * @property {number | string} [props.rows=2] - The height of `textarea` in average character heights. Default value: `2`.
 * @property {string} [props.value=undefined] - The value of the `textarea` element. Default value: `undefined`.
 */

/**
 * GWOCU's textarea component
 *
 * @component
 * @param {TextareaProps} props - The props for the textarea component.
 * @param {React.Ref} ref - The ref passed to the textarea element.
 * @returns {JSX.Element} The rendered textarea component.
 *
 * @example
 * <Textarea placeholder="Enter a valid description" error />
 */
export const Textarea = forwardRef(
  (
    /** @type { TextareaProps } */ {
      autoComplete = "off",
      autoFocus = false,
      className = undefined,
      cols = 20,
      defaultValue = undefined,
      disabled = false,
      error = false,
      fullWidth = true,
      id = undefined,
      minLength = 0,
      maxLength = undefined,
      name = undefined,
      onBlur = undefined,
      onChange = undefined,
      onKeyDown = undefined,
      placeholder = undefined,
      readOnly = false,
      required = false,
      resize = "none",
      rows = 2,
      title,
      value = undefined,
    },
    /** @type { React.RefAttributes<HTMLInputElement> } */ ref
  ) => {
    const classes = `${className || ""} ${error ? " invalid" : ""}`;
    return (
      <StyledTextarea
        id={id}
        ref={ref}
        className={classes}
        cols={cols}
        $fullWidth={fullWidth}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        resize={resize}
        rows={rows}
        title={title}
        value={value}
      />
    );
  }
);
