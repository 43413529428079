import { Editor } from "@monaco-editor/react";
import { useEffect } from "react";

/**
 * GWOCU's code editor component
 *
 * A component for editing and writing code with syntax highlighting and validation.
 *
 * For JSON format, you can pass an object as the `defaultValue`, and it will be automatically stringified.
 * For other languages, pass a stringified version of the content.
 *
 * @component
 * @param {Object} props - The props for the code editor component.
 * @param {boolean} [props.darkMode=true] - If `true`, the editor uses a dark theme. Default value: `true`.
 * @param {string} [props.defaultLanguage="json"] - Specifies the language used for syntax highlighting and validation when the editor loads. See the full list [here](https://www.npmjs.com/package/@monaco-editor/react). Default value: `"json"`.
 * @param {string | object} [props.defaultValue=""] - The initial content of the editor. If the format is JSON, you can pass a JavaScript object instead of a stringified version. Default value: `""`.
 * @param {string} [props.height="100px"] - The height of the editor. Default value: `"100px"`.
 * @param {string} [props.id=undefined] - The unique identifier for the code editor. Default value: `undefined`.
 * @param {function} [props.onChange=undefined] - Callback that receives the current content of the editor. It doesn't trigger content updates, only reads changes as they happen. Default value: `undefined`.
 * @param {function} [props.onMount=undefined] - Executes once the editor has loaded and is ready to use. Default value: `undefined`.
 * @param {function} [props.onValidate=undefined] - Triggers with any syntax or validation errors found in the code. Default value: `undefined`.
 * @param {string | object} [props.value=""] - Sets the editor content. Does not make the editor controlled, so it won't update if this prop changes externally. If the format is JSON, you can pass a JavaScript object instead of a stringified version. Default value: `""`.
 * @returns {JSX.Element} The rendered code editor component.
 *
 * @example
 * <CodeEditor
 *   defaultValue={{ key: "value" }} // For JSON format
 *   onChange={handleChange}
 *   darkMode={false}
 * />
 */
export const CodeEditor = ({
  darkMode = true,
  defaultLanguage = "json",
  defaultValue = "",
  height = "100px",
  id,
  onChange,
  onMount,
  onValidate,
  value,
}) => {

  const formattedValue =
    !!value && defaultLanguage === "json" && typeof value === "object"
      ? JSON.stringify(value, null, 2)
      : value;
  const formattedDefaultValue =
    !!defaultValue && defaultLanguage === "json" && typeof defaultValue === "object"
      ? JSON.stringify(defaultValue, null, 2)
      : defaultValue;

  return (
    <Editor
      id={id}
      defaultLanguage={defaultLanguage}
      defaultValue={formattedDefaultValue}
      height={height}
      onChange={onChange}
      value={formattedValue}
      theme={darkMode ? "vs-dark" : "light"}
      onValidate={onValidate}
      onMount={onMount}
    />
  );
};
