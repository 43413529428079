import { forwardRef } from "react";
import styled, { css } from "styled-components";

const sizes = {
  xs: "2rem",
  sm: "2.4rem",
  md: "3.2rem",
  lg: "4.8rem",
};

const StyledIcon = styled.span`
  font-size: ${({ $size }) => sizes[$size]};
  line-height: 0;
  color: ${({ color }) => `var(--btn-${color}-icon-clr)`};
  transition: color var(--transition-duration-normal, 250ms) ease-in;

  ${({ color, href, $interactive }) => {
    if (href || $interactive) {
      return css`
        &:hover,
        &:active,
        &:focus-visible {
          color: var(--btn-${color}-icon-clr-hover);
          cursor: pointer;
        }
      `;
    }
  }};

  & svg {
    font-size: inherit;
    color: inherit;
  }
`;

/**
 * @typedef {Object} IconProps
 * @property {React.ReactNode} props.children - The icon or content to be displayed.
 * @property {string} [props.className=undefined] - Adds a custom className for the component. Default value: `undefined`.
 * @property {"primary" | "secondary" | "danger"} [props.color="secondary"] - The color of the icon. Default value: `"secondary"`.
 * @property {string} [props.href=undefined] - If provided, the icon will be wrapped in an `<a>` tag, making it a clickable link. Default value: `undefined`.
 * @property {boolean} [props.interactive=false] - If `true`, the icon will have a background color change and a pointer cursor on hover. Default value: `false`.
 * @property {function} [props.onClick=undefined] - The `click` event handler. Default value: `undefined`.
 * @property {string} [props.rel="noopener noreferrer"] - Specifies the relationship between the current document and the linked document. Default value: `"noopener noreferrer"`.
 * @property {"xs" | "sm" | "md" | "lg"} [props.size="md"] - The size of the icon. Default value: `"md"`.
 * @property {string} [props.target="_blank"] - Specifies where to open the linked document. Default value: `"_blank"`.
 */

/**
 * GWOCU's icon component.
 *
 * This component is used to wrap icons, allowing for easy customization of size and color.
 *
 * If an `href` prop is provided, the icon will be wrapped in an `<a>` tag, making it a clickable link.
 *
 * When the `interactive` prop is set to `true`, the icon will change its background color and display a pointer cursor on hover.
 *
 * @component
 * @param {IconProps} props - The component's props.
 * @param {React.Ref} ref - The ref passed to the icon element.
 * @returns {JSX.Element} The rendered icon component.
 *
 * @example
 * <Icon size="lg" color="danger">
 *  <SomeMaterialUiIcon/>
 * </Icon>
 */
export const Icon = forwardRef(
  (
    /** @type {IconProps} */ {
      children,
      size = "md",
      color = "secondary",
      className,
      href,
      target = "_blank",
      rel = "noopener noreferrer",
      interactive = false,
      onClick,
    },
    /** @type {React.RefAttributes<HTMLInputElement>} */ ref
  ) => {
    if (href) {
      return (
        <StyledIcon
          ref={ref}
          $size={size}
          color={color}
          className={className}
          href={href}
          target={target}
          rel={rel}
          as="a"
        >
          {children}
        </StyledIcon>
      );
    }
    return (
      <StyledIcon
        $size={size}
        color={color}
        className={className}
        ref={ref}
        $interactive={interactive}
        onClick={onClick}
      >
        {children}
      </StyledIcon>
    );
  }
);
