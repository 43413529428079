import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Form, Icon, InputText, Select, TextEditor, Toolbar } from '../../UI';
import {encodebody, getDecodedBody} from "../../utils/utils.js";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import "./taskview.css";

function Taskview({ clientNr, explorerId, workflowName, taskId, authorization, updateGraphView }) {
  const [task, setTask] = useState(null);
  const [selectedType, setSelectedType] = useState("circle");
  const [selectedTaskType, setSelectedTaskType] = useState("normal");
  const [selectedApi, setSelectedApi] = useState("");
  const [apis, setApis] = useState([]);

  const typeOptions = ["circle","cross","diamond","square","star","triangle","wye"];
  const taskTypeOptions = ["normal", "compliance"];

  useEffect(() => {
    const fetchApisAndTask = async () => {
      const apiBaseUrl = process.env.REACT_APP_CENTRAL_BACK;

      // Fetch APIs
      const fetchApis = async () => {
        const myBody = {
          clientNr,
          explorerId,
        };
        try {
          const apisResponse = await axios.post(`${apiBaseUrl}/api/queryall`, encodebody(myBody));
          const myEmptyApi = { apiName: "" };
          const myApis = getDecodedBody(apisResponse.data);
          myApis.unshift(myEmptyApi);
          setApis(myApis);
        } catch (error) {
          console.error("Error fetching APIs:", error);
        }
      };

      // Fetch task
      const fetchTask = async () => {
        const requestBody = {
          clientNr,
          explorerId,
          workflowName,
          taskId,
        };

        try {
          const response = await axios.post(`${apiBaseUrl}/task/query`, encodebody(requestBody), {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = getDecodedBody(response.data);
          setTask(data);
          setSelectedType(data.symbolType);
          setSelectedTaskType(data.taskType);
          setSelectedApi(data.apiName);
        } catch (error) {
          console.log('Error fetching task:', error);
        }
      };

      await fetchApis();
      await fetchTask();
    };

    fetchApisAndTask();
  }, [workflowName, taskId]);


  const handleDescriptionChange = (value) => {
  setTask((prevTask) => ({
    ...prevTask,
    description: value,
  }));
};

  const handleNameChange = (event) => {
    setTask((prevTask) => ({
      ...prevTask,
      name: event.target.value,
    }));

  };

  const handleUpdate = async () => {
    const apiUrl = process.env.REACT_APP_CENTRAL_BACK + '/task/update';

    // Define the request body
    const requestBody = {
      clientNr: clientNr,
      explorerId: explorerId,
      workflowName:workflowName,
      taskId:taskId,
      taskType: selectedTaskType,
      symbolType: selectedType,
      apiName: selectedApi,
      name:task.name,
      description: task.description
    };

      const myResponse = await axios.post(apiUrl, encodebody(requestBody));
      alert("Task was succesfully updated.");
      updateGraphView();

  };

  return (task
    ? (
        <>
          <header className="section-right__toolbar-container">
            <Toolbar>
              {(authorization.designer || authorization.owner) && (
                <Toolbar.Button onClick={handleUpdate}>Update</Toolbar.Button>
              )}
              <Toolbar.Item>
                <Icon href="https://wiki.gwocu.com/en/GWOCU-Studio/tasks-detail-panel">
                  <HelpCenterIcon />
                </Icon>
              </Toolbar.Item>
            </Toolbar>
          </header>
          <div className="section-right__content">
            <Form className="section-right__form">
              <Form.Control>
                <Form.Label htmlFor="taskName">Task Name</Form.Label>
                <InputText
                  id="taskName"
                  value={task.name}
                  onChange={handleNameChange}
                  disabled={!authorization.designer && !authorization.owner }
                />
              </Form.Control>
              <Form.Row>
                <Form.Control>
                  <Form.Label htmlFor="taskType">Task Type</Form.Label>
                  <Select
                    id="taskType"
                    value={selectedTaskType}
                    className="taskview__select-task-type"
                    onChange={(e) => setSelectedTaskType(e.target.value)}
                    disabled={!authorization.designer && !authorization.owner}
                    options={taskTypeOptions.map(type => ({label: type, value: type}))}
                    />
                </Form.Control>
                <Form.Control>
                  <Form.Label htmlFor="api">Implements API</Form.Label>
                  <Select
                    id="api"
                    value={selectedApi}
                    onChange={(e) => setSelectedApi(e.target.value)}
                    disabled= {!authorization.designer && !authorization.owner}
                    options={apis.map(({name: apiName}) => ({label: apiName, value: apiName}))}
                  />
                </Form.Control>
              </Form.Row>
              <Form.Control>
                <Form.Label htmlFor="nodeType">Node Type</Form.Label>
                <Select
                  id="nodeType"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  disabled={!authorization.designer && !authorization.owner}
                  options={typeOptions.map((type) => ({label: type, value: type}))}
                />
              </Form.Control>
              <Form.Control className="panel__text-editor-section">
                <Form.Label htmlFor="taskviewDescription">Description</Form.Label>
                <TextEditor
                  value={task.description}
                  onChange={handleDescriptionChange}
                  readOnly={!authorization.designer && !authorization.owner}
                />
              </Form.Control>
            </Form>
          </div>
        </>
      ) : (
        <p>Loading Task information...</p>
      )
  );
}

export default Taskview;
