import React, { useRef, useMemo, useState } from 'react';
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import axios from "axios";
import { Button, Toolbar } from '../../UI';
import { encodebody } from "../../utils/utils.js";
import 'react-complex-tree/lib/style-modern.css';
import Modalapidefexport from '../modalapidefexport/Modalapidefexport';
import Modalapidefimport from '../modalapidefimport/Modalapidefimport';
import './myfoldertree.css';

const MyFolderTree = ({myItems, onSelectApi, clientNr, explorerId }) => {
  const tree = useRef();
  const [focusedItem, setFocusedItem] = useState("root");
  const [isApiDefExportModalOpen, setIsApiDefExportModalOpen] = useState(false);
  const [isApiDefImportModalOpen, setIsApiDefImportModalOpen] = useState(false);
  const items = useMemo(() => ({ ...myItems }), []);

  const dataProvider = useMemo(
    () =>
      new StaticTreeDataProvider(items, myRename ),
    [items]
  );

  function myRename(item,newName) {
    if (item.index == "Unassigned" || item.index === "MyFolders")
    { alert("Systems folders can't be renamed")
      return { ...item, data: item.data };
    }
    else
    {
      if (item.isFolder)
      {
        alert("renaming Folder");
        const parentIndex = findParent(item.index,items)
        alert(parentIndex);

        items[parentIndex].children = items[parentIndex].children.filter(child => child !== item.index);
        // add renamed child to parent
        items[parentIndex].children.push(newName);

        // add new child with new name
        items[newName] = { data: newName, index: newName, isFolder: true ,children: item.children};
        // delete old child
        delete items[item.index] ;
        // save structure

        const saveFolderPayload = {
          clientNr: clientNr,
          explorerId: explorerId,
          items: items
        }

        axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload));

        dataProvider.onDidChangeTreeDataEmitter.emit([parentIndex]);
      return { ...item, data: newName };
      }
      else
      {
        // item is an API
        // remove old child from parent
        const parentIndex = findParent(item.index,items)

        items[parentIndex].children = items[parentIndex].children.filter(child => child !== item.index);
        // add renamed child to parent
        items[parentIndex].children.push(newName);

        // delete old child
        delete items[item.index] ;
        // add new child with new name
        items[newName] = { data: newName, index: newName};
        // save structure

        const saveFolderPayload = {
          clientNr: clientNr,
          explorerId: explorerId,
          items: items
        }

        axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload));

        const saveApiPayload = {
          clientNr: clientNr,
          explorerId: explorerId,
          oldName: item.index,
          newName: newName
        }

        axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/changename', encodebody(saveApiPayload));
        dataProvider.onDidChangeTreeDataEmitter.emit([parentIndex]);
        tree.current.moveFocusUp()
        return { ...item, data: newName };
      }
    }
  }

  const injectItem = () => {
    if (!focusedItem)
    {
      alert("please select a location to create folder");
      return;
    }

    if (focusedItem === "Unassigned")
    {
      alert("It is not allowed to create a folder in the Unassigned Apis folder");
      return;
    }


    const parentFocusedIndex = findParent(focusedItem,items)
    // if focusedItem is a folder then the parent is itself
    let parent = focusedItem
    if (items[focusedItem].isFolder)
    {
      parent = focusedItem
    }
    else
    {
      parent = parentFocusedIndex ;
    }

    

    const rand = `${Math.random()}`;
    items[rand] = { data:'New Folder', index: rand, isFolder: true, children: []};
    items[parent].children.push(rand);

    const saveFolderPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      items: items
    }

    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload)); //

    dataProvider.onDidChangeTreeDataEmitter.emit([parent]);
  };

  const injectApi = () => {

    let parent = "Unassigned"

    const rand ="New Api " + `${Math.random()}`;
    const myNewAPiPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      name: rand,
      description: "none",
      urlRoute: "https://",
      resourcePath: "https://",
      headers:[],
      method: "POST",
      requestBodyType: "JSON",
      responseBodyType: "JSON"
    }

    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/register', encodebody(myNewAPiPayload)); //
    items[rand] = { data: rand, index: rand};
    items[parent].children.push(rand);
    dataProvider.onDidChangeTreeDataEmitter.emit([parent]);

    const saveFolderPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      items: items
    }

    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload)); //

    alert("Api created in Unassigned Folder!");
  };

  const copyApi = () => {
    if (items[focusedItem].isFolder)
    {
      alert("Please select an api to copy");
      return;
    }

    let parent = "Unassigned"

    const newApiName ="Copy of " + focusedItem + " " + `${Math.random()}`;
    const copyAPiPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      apiToCopy: focusedItem,
      newApiName: newApiName
    }

    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/copy', encodebody(copyAPiPayload)); //

    items[newApiName] = { data: newApiName, index: newApiName};
    items[parent].children.push(newApiName);
    dataProvider.onDidChangeTreeDataEmitter.emit([parent]);

    const saveFolderPayload = {
      clientNr: clientNr,
      explorerId: explorerId,
      items: items
    }

    axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload)); //

    alert("Api copied to Unassigned Folder!");
  };


  const removeItem = () => {
    if (!focusedItem || focusedItem ==="root")
    {
      alert("please select an item to remove");
      return;
    }
    const parentFocusedIndex = findParent(focusedItem,items)
    
    if (focusedItem ==="MyFolders" )
    {
      alert("MyFolders is a systems folder and can't be removed.");
      return;
    }

    if (focusedItem ==="Unassigned")
    {
      alert("Unassigned is a systems folder and can't be removed.");
      return;
    }

    if (items[focusedItem].isFolder)
    {
      if (items[focusedItem].children.length !== 0)
      {
        alert("Folder has sub-items please delete all sub-items first")
      }
      else
      {
        if (parentFocusedIndex) {
            const parentItem = items[parentFocusedIndex];
            parentItem.children = parentItem.children.filter(child => child !== focusedItem);
        }

        // Remove the child from the items object
        delete items[focusedItem];
        const saveFolderPayload = {
            clientNr: clientNr,
            explorerId: explorerId,
            items: items
        }
        axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload)); //

        dataProvider.onDidChangeTreeDataEmitter.emit([parentFocusedIndex]);
      }
      setFocusedItem(parentFocusedIndex);
      return;
    }
    else
      {
      if (parentFocusedIndex)
        {
        if (parentFocusedIndex !== "Unassigned")
        {
          //remove item from current parent
          const parentItem = items[parentFocusedIndex];
          parentItem.children = parentItem.children.filter(child => child !== focusedItem);
          // delete items[focusedItem];
          dataProvider.onDidChangeTreeDataEmitter.emit([parentFocusedIndex]);
          // move item to unassigned
          items["Unassigned"].children.push(focusedItem);

          const saveFolderPayload = {
              clientNr: clientNr,
              explorerId: explorerId,
              items: items
        }
        axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload));

        dataProvider.onDidChangeTreeDataEmitter.emit(["Unassigned"]);
        alert('API was moved to unasigned directory')
        }
        else
          { const parentItem = items[parentFocusedIndex];
            parentItem.children = parentItem.children.filter(child => child !== focusedItem);
            // delete items[focusedItem];
            delete items[focusedItem];
            dataProvider.onDidChangeTreeDataEmitter.emit([parentFocusedIndex]);

            const deletePayload = {
              clientNr: clientNr,
              explorerId: explorerId,
              name: focusedItem
            }

            axios.post(process.env.REACT_APP_CENTRAL_BACK + '/api/delete', encodebody(deletePayload)); //

            const saveFolderPayload = {
              clientNr: clientNr,
              explorerId: explorerId,
              items: items
            }

            axios.post(process.env.REACT_APP_CENTRAL_BACK + '/folder/update', encodebody(saveFolderPayload)); //


            alert('API was permanently deleted form system')
            tree.current.moveFocusUp()
          }
        }
      // Remove the child from the items object
    }
    // items.root.children.pop();
    // dataProvider.onDidChangeTreeDataEmitter.emit(['root']);
  };


  function handleFocusedItem(item) {
    setFocusedItem(item.index);
    // check if item is an api
    if (!item.isFolder)
    {
      
      onSelectApi(item.index);
    }
    else
    {
      onSelectApi(null)
    }
    
  }

  function findParent(childIndex, items) {
    if (childIndex === "root")
    { return "root"}
    for (const [parentIndex, parentItem] of Object.entries(items)) {
      if (parentItem.children && parentItem.children.includes(childIndex)) {
        return parentIndex;
      }
    }
  return null; // Return null if the parent is not found
}

function handleDrag(myitems)
{
  //prohibit dragging of Unassigned and MyFolders
  if (myitems.some(item => item.index === "Unassigned" || item.index === "MyFolders"))
  {
    return false;
  }
  // prohibit dragging of more than one item

  if (Object.keys(myitems).length !== 1)
    {
      return false;
    }
  return true;
}

function handleDrop(myitems, target)
{
  if (target.targetItem === "Unassigned")
  {
    return false;
  }
  else
  {
    return true;
  }
}

  return (
    <div className="my-folder-tree">
      <div className="my-folder-tree__toolbar-container">
        <Toolbar>
         
          <Toolbar.Button onClick={injectItem}>New Folder</Toolbar.Button>
          <Toolbar.Button onClick={injectApi}>New Api</Toolbar.Button>
          <Toolbar.Button onClick={copyApi}>Copy Api</Toolbar.Button>
          <Toolbar.Item>
            <Button color="danger" size="sm" onClick={removeItem}>Remove Item</Button>
          </Toolbar.Item>
          </Toolbar>
          <Toolbar>
          <Toolbar.Button onClick={() => setIsApiDefImportModalOpen(true)}>Import APIs</Toolbar.Button>
          <Toolbar.Button onClick={() => setIsApiDefExportModalOpen(true)}>Export APIs</Toolbar.Button>
        </Toolbar>
      </div>
      <UncontrolledTreeEnvironment
        dataProvider={dataProvider}
        getItemTitle={item => item.data}
        viewState={{
          ['tree-1']: {
            focusedItem: 'root'
          },
        }}
        canDragAndDrop={true}
        canReorderItems={true}
        canDropOnFolder={true}
        canDropOnNonFolder={false}
        onFocusItem = {handleFocusedItem}
        canDrag={handleDrag}
        canDropAt={handleDrop}
      >
        <Tree
          treeId="tree-1"
          rootItem="root"
          treeLabel="API Folders"
          ref={tree}
        />
      </UncontrolledTreeEnvironment>
      {/* Render the Modalapidefexport component conditionally */}
      <Modalapidefexport 
        clientNr={clientNr}
        explorerId={explorerId}
        open={isApiDefExportModalOpen} 
        onClose={() => setIsApiDefExportModalOpen(false)} 
      />
      <Modalapidefimport 
        clientNr={clientNr}
        explorerId={explorerId}
        open={isApiDefImportModalOpen} 
        onClose={() => setIsApiDefImportModalOpen(false)} 
      />
    </div>
  );
};

export default MyFolderTree;