import styled from "styled-components";
import { dash, rotate } from "./animations";

const StyledSpinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  font-size: inherit;
  width: 1.25em;
  height: 1.25em;

  & .path {
    stroke: currentColor;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

/**
 * GWOCU's spinner component
 *
 * Its width and height depend on the container's font size
 * @component
 * @param {Object} props - The component's props
 * @param {string} [props.className=undefined] - Adds custom className for the component. Default value: `undefined`.
 * @param {number | string} [props.thickness=10] - Adjusts the thickness of the ring, where 1 is the thinnest and 10 is the thickest. Default value: 10.
 * @returns {JSX.Element} The rendered spinner component.
 *
 * @example
 * <span>
 *   <Spinner thickness={7}/>
 *   This is a small, thin spinner
 * </span>
 */
export const Spinner = ({ className, thickness = 10 }) => {
  // Ensure the thickness is within bounds
  const boundedThickness = Math.max(1, Math.min(10, thickness));

  return (
    <StyledSpinner viewBox="0 0 50 50" className={className}>
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={boundedThickness}
      />
    </StyledSpinner>
  );
};
